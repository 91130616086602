<template>
  <div
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="max-w-screen-2xl"
    :class="[...marginClasses]"
  >
    <div class="mx-4 grid grid-cols-1 items-start md:mx-16 lg:grid-cols-12">
      <!-- Optional Paragraph -->
      <div
        v-if="showParagraph"
        class="col-start-1 mb-2.5 sm:mt-16 lg:col-end-5 lg:mb-0"
      >
        <div>
          <p v-if="blok.paragraph_overline" class="text-xl font-medium">
            {{ blok.paragraph_overline }}
          </p>

          <div class="my-3 md:my-3.5">
            <h2
              v-if="blok.paragraph_headline"
              class="text-4xl font-semibold 2xl:text-5xl"
            >
              {{ blok.paragraph_headline }}
            </h2>
            <p v-if="blok.paragraph_subline" class="text-xl font-medium">
              {{ blok.paragraph_subline }}
            </p>
          </div>

          <div class="flex w-full justify-between">
            <CmsLink
              v-if="link && showLink && !link.cta_variant"
              :blok="link"
              type="underline"
              class="mb-4"
              text-size="md"
            >
              <template #iconAfter?>
                <LazyIconFielmannArrowRight class="size-4" />
              </template>
              {{ link.cta_label }}
            </CmsLink>
            <CmsButton
              v-else-if="link && showLink"
              :blok="link"
              class="mb-4"
              :type="toButtonType(link.cta_variant)"
              :no-padding="link.cta_variant === 'link-alternative'"
              :is-link="true"
            >
              {{ link.cta_label }}
              <template #iconAfter?>
                <LazyIconFielmannArrowRight class="size-4" />
              </template>
            </CmsButton>
          </div>
        </div>
      </div>
      <Suspense>
        <ProductSliderCarousel
          class="min-h-[362px]"
          :uid="blok._uid"
          :show-paragraph="!!showParagraph"
          :product-ids="productIds"
          :product-reference-keys="productReferenceKeys"
          @click:product="trackProductClick"
          @change="trackView"
          @intersect="trackView"
        />
        <template #fallback>
          <Skeleton
            :class="{
              'lg:col-start-5 lg:col-end-13 lg:mt-4': showParagraph,
              'col-start-1 col-end-13 mt-4': !showParagraph,
            }"
          />
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getLatestCategory,
  type Product as ProductType,
} from '@scayle/storefront-nuxt'
import { useStoryblokMargins } from '~/storyblok/composables/useStoryblokMargins'
import type { SbProductSlider } from '~/storyblok/types/storyblok'
import { isValidLink } from '~/storyblok/utils/links'

const props = defineProps({
  blok: {
    type: Object as PropType<SbProductSlider>,
    default: () => {},
  },
})

const route = useRoute()
const { marginClasses } = useStoryblokMargins(props.blok)

const listingMetaData = {
  name: `ProductSlider-${props.blok.paragraph_headline}`,
  id: '',
}

const link = computed(() => getLinkRef(props.blok.cta_ref))

const showLink = computed(() => isValidLink(link.value?.cta_url))

const productIds = computed(
  () =>
    props.blok?.product_ids?.split(',')?.map((id: string) => parseInt(id)) ??
    [],
)

const productReferenceKeys = computed(
  () =>
    props.blok?.product_reference_keys
      ?.split(',')
      ?.map((referenceKey: string) => referenceKey.trim())
      ?.filter(Boolean) ?? [],
)

const showParagraph = computed(() => props.blok.paragraph_active)

const {
  trackSelectItem,
  trackViewItemList,
  trackPromotionClick,
  trackPromotionView,
} = await useTrackingEvents()

const trackProductClick = ({
  product,
  index,
}: {
  product: ProductType
  index: number
}) => {
  const category = getLatestCategory(product.categories)
  if (category) {
    trackSelectItem(
      product,
      {
        categoryName: category?.categoryName || '',
        categoryId: category?.categoryId,
      },
      listingMetaData,
      index,
      `${route.name?.toString()}|ProductSlider|${props.blok.paragraph_headline}`,
    )
  }

  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}

const trackView = ({
  products,
  index,
}: {
  products: ProductType[]
  index: number
  perPage: number
}) => {
  trackViewItemList(
    products,
    listingMetaData,
    index,
    0,
    `${route.name?.toString()}|ProductSlider|${props.blok.paragraph_headline}`,
  )

  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView({ ...props.blok, products })
  }
}
</script>
